<template>
  <div>
    <div v-if="!isLoadingDesktop">
      <div v-if="isLoading || totalPage > 0">
        <div v-for="result in listResult" :key="result.lottery_time" class="resultCard">
          <div class="d-flex justify-content-between resultCard__title" :class="{ mobile: isMobile }">
            <span>{{ formatDate(result.lottery_time, 'dddd - DD/MM/YYYY') }}</span
            ><span>Xổ số Miền Nam</span>
          </div>

          <table width="100%" class="resultCard__table" :class="{ mobile: isMobile }">
            <colgroup>
              <col span="1" :style="{ width: isMobile ? '25%' : '13%' }" />
              <col
                span="1"
                :style="`width: ${isMobile ? 72 / result.lottery_result.length : 74 / result.lottery_result.length}%;`"
                v-for="res in result.lottery_result"
                :key="res.product_id"
              />
              <col span="1" style="width: 15%;" v-if="!isMobile" />
            </colgroup>
            <tr>
              <th>Giải</th>
              <th v-for="res in result.lottery_result" :key="res.product_id" class="text-center blue-color">
                {{ res.product_name }}
              </th>
              <th class="text-right" v-if="!isMobile">Giải thưởng</th>
            </tr>
            <tr v-for="(reward, rewardIndex) in displayRewardList" :key="reward.title">
              <td>
                <template v-if="isMobile">{{ reward.reward }}</template>
                <template v-else>{{ reward.title }}</template>
              </td>
              <td
                v-for="res in result.lottery_result"
                :key="res.product_id"
                :class="{
                  'reward-8': rewardIndex === 0,
                  'reward-db': rewardIndex === 8,
                  'blue-color': rewardIndex === 1
                }"
              >
                <span
                  v-for="(number, index) in getGameRewardResults(res.lottery_result, rewardIndex)"
                  :key="`number-${rewardIndex}-${number}-${index}`"
                  >{{ number }}</span
                >
              </td>
              <td class="text-right" v-if="!isMobile">{{ formatPrice(reward.reward) }}</td>
            </tr>
          </table>
        </div>
      </div>
      <p v-else class="text-center pt-4">Chưa có kết quả</p>
      <div class="d-none d-lg-block" v-if="totalPage > pagination.pageSize && pagination.currentPage > 0">
        <div class="d-flex my-2 mt-lg-4">
          <a @click="scrollBehavior()">
            <b-pagination
              class="custom-pagination"
              pills
              v-model="pagination.currentPage"
              :total-rows="totalPage"
              hide-ellipsis
              first-number
              last-number
              hide-goto-end-buttons
              :per-page="pagination.pageSize"
              @input="getGameResultPaging()"
              next-class="d-none"
              prev-class="d-none"
            ></b-pagination>
          </a>
        </div>
      </div>
    </div>
    <div class="d-block d-lg-none">
      <div v-if="!isLoadEnd" class="loading mb-3">
        <div class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
    <div class="d-none d-lg-block">
      <div v-if="isLoading || isLoadingDesktop" class="loading mb-3">
        <div class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { isMobile } from 'mobile-device-detect';
export default {
  components: {},
  props: [
    'listResult',
    'totalPage',
    'pagination',
    'isLoading',
    'isLoadingDesktop',
    'isLoadEnd',
    'getByUser',
    'rewards'
  ],
  data() {
    return {
      // totalPage: 0,
      fromTime: '',
      toTime: '',
      timeOut: null,
      resizeTimeout: null,
      mobileFlag: false,
      saveWidth: null,
      rewardList: [
        {
          title: 'Giải Tám',
          reward: 100000
        },
        {
          title: 'Giải Bảy',
          reward: 200000
        },
        {
          title: 'Giải Sáu',
          reward: 400000
        },
        {
          title: 'Giải Năm',
          reward: 1000000
        },
        {
          title: 'Giải Tư',
          reward: 3000000
        },
        {
          title: 'Giải Ba',
          reward: 10000000
        },
        {
          title: 'Giải Nhì',
          reward: 15000000
        },
        {
          title: 'Giải Nhất',
          reward: 30000000
        },
        {
          title: 'Giải Đặc biệt',
          reward: 2000000000
        }
      ],
      rewardListMB: [
        {
          title: 'Giải 8',
          reward: '100K'
        },
        {
          title: 'Giải 7',
          reward: '200K'
        },
        {
          title: 'Giải 6',
          reward: '400K'
        },
        {
          title: 'Giải 5',
          reward: '1TR'
        },
        {
          title: 'Giải 4',
          reward: '3TR'
        },
        {
          title: 'Giải 3',
          reward: '10TR'
        },
        {
          title: 'Giải 2',
          reward: '15TR'
        },
        {
          title: 'Giải 1',
          reward: '30TR'
        },
        {
          title: 'Giải ĐB',
          reward: '2 TỶ'
        }
      ]
    };
  },
  computed: {
    isMobile() {
      return isMobile;
    },
    displayRewardList() {
      return isMobile ? this.rewardListMB : this.rewardList;
    }
  },
  mounted() {
    if (!this.$route.params.kol_code) {
      this.$store.commit('setModalLogin', false);
      this.$store.commit('setModalRegister', false);
    }
  },
  created() {
    this.saveWidth = window.innerWidth;
    window.addEventListener('scroll', this.handlerScroll);
    window.onresize = this.handleResize;
    if (this.isReview) {
      window.history.pushState(null, '', window.location.href);
      window.onpopstate = function() {
        window.history.pushState(null, '', window.location.href);
      };
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handlerScroll);
  },
  methods: {
    handlerScroll() {
      let bottomOfWindow =
        window.scrollY + window.innerHeight + 100 >= document.documentElement.scrollHeight &&
        window.innerWidth <= this.getMobileThresshold();
      if (bottomOfWindow && !this.isLoading) {
        this.loadMore();
      }
    },
    handleResize() {
      const isMobile = window.innerWidth <= this.getMobileThresshold();
      if (this.mobileFlag === isMobile) {
        return;
      }
      this.mobileFlag = isMobile;

      if (this.resizeTimeout) {
        clearTimeout(this.resizeTimeout);
      }
      this.resizeTimeout = setTimeout(() => {
        this.$emit('onResize');
      }, 1000);
    },
    loadMore() {
      if (this.timeOut) {
        clearTimeout(this.timeOut);
      }
      this.timeOut = setTimeout(() => {
        this.$emit('getGameResult');
      }, 100);
    },

    scrollBehavior() {
      document.getElementById('appScroll').scrollIntoView({
        behavior: 'smooth'
      });
    },
    getGameResultPaging() {
      this.$emit('updatePagination', this.pagination);
    },
    getGameRewardResults(lotteryResult, rewardIndex) {
      return JSON.parse(lotteryResult).reverse()[rewardIndex];
    }
  }
};
</script>
<style lang="scss" scoped>
.loading {
  // background-color: #fff;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}
@media (max-width: map-get($grid-breakpoints, 'lg')) {
  .loading {
    height: 10vh;
  }
  .boxFilter {
    // display: none !important;
  }
}
.resultCard {
  background: #fff;
  margin-bottom: 20px;
  border-radius: 5px;
  overflow: hidden;

  &__title {
    background: #00509d;
    color: #fff;
    line-height: 50px;
    padding: 0 20px;

    &.mobile {
      background: #00509d;
      color: #fff;
      line-height: 24px;
      padding: 0 14px;
    }
  }

  &__table {
    td,
    th {
      text-align: center;
      vertical-align: middle;
      padding: 5px 20px;
      border: 1px solid #cecece;
    }
    th {
      text-align: center;
      vertical-align: middle;
      padding: 0px 0px;
      background-color: #e5edf5;
      font-weight: 700;
    }
    span {
      font-weight: 700;
      display: inline-block;
      margin: 3px 8px;
      font-size: 20px;
    }
    .name {
      padding-left: 20px !important;
    }
    &.mobile {
      tr,
      td,
      td {
        padding: 0px 0px;
        text-align: center;
        vertical-align: middle;
        border: 1px solid #cecece;
      }
      span {
        font-size: 14px;
      }
      .name {
        padding-left: 10px !important;
      }
    }
  }
}
.reward-db {
  font-size: 18px !important;
  color: #d31904;
}

.reward-8 span {
  font-size: 22px !important;
  color: #d31904;
}
.blue-color {
  color: #00509d !important;
}
.text-center {
}
</style>
